<template>
  <div>
    <modal
      ref="modal"
      class-name="w-full md:w-500px 2xl:w-600px"
    >
      <component
        :is="component"
        :nin="nin"
        :otp-verification-id="otpVerificationId"
        @resend-otp="otpResent"
        @show-nin-help="showNinHelpModal"
        @update-profile="determineNextStep"
        @verify-otp="determineNextStep"
        @mounted="componentMounted"
      />
    </modal>

    <modal className="w-full md:w-450px" ref="ninHelpModal">
      <h1 class="text-base md:text-xl font-bold mb-6">
        Need help finding out your NIN?
      </h1>

      <div class="border border-blue-200 rounded-sm mb-6">
        <div class="px-6 py-6">
          Just dial <span class="font-mono font-bold pt-2 pb-2 px-3 bg-blue-200 rounded-sm" style="letter-spacing: 1px">
            *346#
          </span>
        </div>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn btn-blue btn-md"
          @click.prevent="$refs.ninHelpModal.close"
        >
          Okay
        </button>
      </div>

    </modal>
  </div>
</template>

<script>
export default {
  name: 'Form2',
  components: {
    Profile: () => import('./Profile'),
    OtpVerification: () => import('./OtpVerification'),
  },
  data() {
    return {
      form: this.$options.basicForm([
        'nin', 'otp_verification_id',
      ]),
    };
  },
  computed: {
    nin() {
      return this.form.data.nin.value;
    },
    component() {
      if (this.otpVerificationId) {
        return 'otp-verification';
      }
      return 'profile';
    },
    otpVerificationId() {
      return this.form.data.otp_verification_id.value;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    componentMounted() {
      this.$emit('mounted');
    },
    determineNextStep({ nin, otp_verification_id: otpVerificationId } = {}) {
      if (otpVerificationId && nin) {
        this.form.data.nin.value = nin;
        this.form.data.otp_verification_id.value = otpVerificationId;
        return;
      }

      this.close();
      this.$emit('complete-form2');
    },
    open() {
      this.$refs.modal.open();
    },
    otpResent({ nin, otp_verification_id: otpVerificationId }) {
      this.form.data.nin.value = nin;
      this.form.data.otp_verification_id.value = otpVerificationId;
    },
    showNinHelpModal() {
      this.$refs.ninHelpModal.open();
    },
  }
};
</script>
